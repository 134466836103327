.form-control-preview {
  margin-bottom: 25px;
}

.form-control-preview .pf-input-parent {
  flex-grow: 1;
}

/* .form-control-preview .remove-form-control {
  margin-left: 3px;
} */

.remove-form-control {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 5px;
  color: var(--color-black);
  border: 1px solid var(--color-black);
  background-color: var(--color-white);
  transition: 0.3s all;
}

.remove-form-control:hover {
  border: 1px solid var(--color-orange);
  background-color: var(--color-orange);
}
.inputype {
  max-width: 82%;
  margin-top: 3px;
}

.buttonhover {
  background-color: #fbe54d;
  border-color: #fbe54d;
  color: #221047;
  
}
.buttonhover:hover {
  background-color: #351771;
  border-color: #351771;
  color: #ffd13c;
}

.buttonhover[disabled] {
  background-color: #ffd13c;
  color: #ffd13c;
}
.tooltip2 {
  position: relative;
  background-color: #f7fff7;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  background-color: #0091e5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 16px 0px;
  position: absolute;
  border-radius: 20px;
  z-index: 1;
  /* bottom: 150%; */
  width: 263px;
  top: 2rem;
  right: -1rem;
  margin-left: -60px;
}

.tooltip2 .tooltiptext::before {
  content: "";
  position: absolute;
  display: block;
  width: auto;
  left: 90%;
  top: 0.5rem;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #5494db;
  transform: translate(-50%, calc(-100% - 5px));
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}

.infoIcon {
  color: #4f4f4f;
}

.infoIcon:hover {
  color: #0091e5;
}
.optionHover:hover {
  font-weight: 600;
}
.start-button {
  background-color: transparent;
  border-color: #221047;
  color: #221047;
}
.start-button:hover {
  background-color: #351771;
  border-color: #351771;
  color: #ffd13c;
}
.tooltip3 {
  position: relative;
  background-color: #f7fff7;
}

.tooltip3 .tooltiptext {
  text-align: justify;
  visibility: hidden;
  background-color: #351771;
  color: #ffd13c;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px 0px;
  position: absolute;
  border-radius: 20px;
  z-index: 1;
  /* bottom: 150%; */
  width: 263px;
  top: 4rem;
  right: -1rem;
  margin-left: -60px;
}

.tooltip3 .tooltiptext::before {
  content: "";
  position: absolute;
  display: block;
  width: auto;
  left: 90%;
  top: 0.5rem;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #351771;
  transform: translate(-50%, calc(-100% - 5px));
}

.tooltip3:hover .tooltiptext {
  visibility: visible;
}
.previewCard {
  height: 70%;
}
@media (max-width: 575px) {
  .generate-btn-wrap .col-6 {
    width: 100%;
    display: flex;
    justify-content: center !important;
    margin-bottom: 20px;
  }
  .inputype {
    max-width: 74%;
  }
  .generate-btn-wrap .button {
    width: 100%;
  }
  .tooltip3 {
    width: 100%;
  }
  .tooltip3 .tooltiptext {
    left: 40%;
  }
  .tooltip2 .tooltiptext {
    width: 220px;
  }
}
@media (max-width: 991px) {
  .inputype {
    max-width: 82%;
  }
}
@media (max-width: 770px) {
  .inputype {
    max-width: 74%;
  }
}
