.upgrade-plan-wrap {
  display: flex;
  align-items: flex-start;
}

.plan-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #4f4f4f;
  border-radius: 16px;
  padding: 20px 20px;
  max-width: 235px;
  margin-right: 16px;
  background-color: var(--color-white);
  cursor: pointer;
}
.plan-card.active {
  display: flex;
  border: 6px solid var(--color-primary);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
}

/* .plan-card:hover:not(.active) {
  border: 6px solid var(--color-primary);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
} */

.plan-desc {
  min-height: 80px;
}

.plan-recommended {
  color: var(--color-dark-purple);
  font-size: 14px;
  background-color: #e3cbef;
  border-radius: 32px;
  text-transform: uppercase;
  padding: 5px 10px;
  display: inline-flex;
}

.plan-pricing {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100px;
}

.plan-features li {
  font-size: 14px;
  margin-bottom: 5px;
  /* color: #221047; */
}

.upgrade-plan {
  display: flex;
}

.plan-active {
  border: 6px solid var(--color-primary);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
}

.bill-divider {
  border-bottom: 1px solid #cecece;
  padding-bottom: 5px;
}

.plan-bill-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 0px 32px 8px;
}

.bill-info {
  margin-top: 50px;
}

.bill-info-title,
.bill-info-content {
  font-size: 14px;
  width: 50%;
}

.bill-info-content {
  text-align: right;
}

.fc-wrap {
  margin-bottom: 15px;
}

.custom-fc {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
}

.cc-input {
  position: relative;
}

.cc-input .cc-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: flex;
  align-items: center;
}

.cc-input .cc-extra {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.cc-input .custom-fc {
  padding-left: 40px;
  padding-right: 130px;
}

.cc-extra input {
  border: none;
  font-size: 14px;
}

.cc-extra .expiry-fc {
  width: 70px;
}

.cc-extra .cvc-fc {
  width: 40px;
}

.expiry-fc::-webkit-inner-spin-button,
.expiry-fc::-webkit-outer-spin-button,
.cvc-fc::-webkit-inner-spin-button,
.cvc-fc::-webkit-outer-spin-button {
  display: none;
}

@media (max-width: 1199px) {
  .upgrade-plan-wrap {
    display: block;
  }

  .upgrade-plan {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .plan-card {
    max-width: 100%;
    flex: 1;
  }
}

@media (max-width: 991px) {
  .upgrade-plan {
    margin-bottom: 30px;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .plan-card {
    max-width: 260px;
    width: 100%;
    flex: initial;
  }
}

@media (max-width: 767px) {
  .plan-card {
    max-width: 300px;
    width: 100%;
    flex: initial;
    margin-right: 0;
  }

  .plan-desc,
  .plan-pricing {
    min-height: auto;
  }
}
