.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: var(--header-height);
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  z-index: 99;
}
#nav-dropdown {
  color: rgb(255, 0, 0);
  border: none;
  background-color: rgb(255, 255, 255);
}
#language {
  width: 10px;
  background-color: rgb(255, 255, 255);
  border: none;
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

.header-nav .avl-credits {
  display: flex;
  align-items: center;
}

/* HAMBURGER */
.hamburger {
  position: relative;
  padding-inline: 6px;
  z-index: 101;
  width: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.hamburger:before,
.hamburger:after,
.hamburger div {
  background: var(--color-dark-purple);
  content: '';
  display: block;
  height: 3px;
  border-radius: 3px;
  margin: 5px 0;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.menu-open .hamburger:before {
  -webkit-transform: translateY(8px) rotate(135deg);
  -ms-transform: translateY(8px) rotate(135deg);
  transform: translateY(8px) rotate(135deg);
}

.menu-open .hamburger:after {
  -webkit-transform: translateY(-8px) rotate(-135deg);
  -ms-transform: translateY(-8px) rotate(-135deg);
  transform: translateY(-8px) rotate(-135deg);
}

.menu-open .hamburger div {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
/* HAMBURGER END */

@media (max-width: 767px) {
  .brand-logo {
    width: 150px;
  }

  .header-nav {
    opacity: 0;
    visibility: hidden;
    gap: 15px;
    position: fixed;
    max-width: 245px;
    right: 0;
    top: var(--header-height);
    background-color: var(--color-white);
    border-bottom: 1px solid #c2c2c2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 24px 40px !important;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s all;
  }

  .menu-open .header-nav {
    opacity: 1;
    visibility: visible;
  }

  .header-nav .nav-item {
    width: 100%;
  }

  .header-nav .button {
    width: 100%;
  }
}
