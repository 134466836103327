.main-div {
  margin-top: 40px;
}
.col-div {
  font-weight: bold;
  color: #351771;
}
.sub-div {
  margin-top: 50px;
}
.text {
  color: black;
  font-weight: 100;
}
ul.text-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.hr-div {
  margin-top: 70px;
  border: 1px solid black;
}
.desc-div {
  margin-top: 50px;
}
.col-desc {
  font-weight: bold;
}
.col-total {
  margin-top: 20px;
  font-weight: bold;
}
.footer-div {
  margin-top: 19px;
}
