.record-using-mic {
  position: absolute;
  right: 20px;
  top: 15px;
}

.recordable-textarea .pf-textarea {
  padding-right: 250px;
}

.template-slider .swiper-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.template-card {
  margin-bottom: 24px;
  background-color: var(--color-white);
  box-shadow: 2px 2px 12px rgba(53, 23, 113, 0.17);
  border-radius: 16px;
  width: 300px;
  overflow: hidden;
}

.template-details {
  padding: 12px;
  height: 10rem;
  /* color: var(--color-dark-blue); */
  color: #351771;
}

.template-image {
  background-color: #c9ede7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 9rem;
}

.swiper-pagination-bullet {
  background-color: transparent;
  border: 2px solid #4f4f4f;
  width: 16px;
  height: 16px;
  transition: 0.3s all;
}

.swiper-pagination-bullet-active {
  background-color: #4f4f4f;
}
textarea:focus-visible {
  outline: 1px solid rgb(0, 0, 0);
  outline-offset: 0px;
}
input:focus-visible {
  outline: 1px solid rgb(0, 0, 0);
  outline-offset: 0px;
}

@media (max-width: 575px) {
  .record-using-mic {
    right: 15px;
  }

  .recordable-textarea .pf-textarea {
    padding-right: 50px;
  }
}
.horizontal-stepper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 24%;
  margin-left: 1rem;
  margin-top: 7rem;
}
.horizontal-stepper-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 27%;
}
@media (max-width: 1032px) {
  .horizontal-stepper-text {
    max-width: 100%;
    margin-left: 2px;
  }
  .horizontal-stepper {
    max-width: 94%;
    margin-left: 1rem;
  }
}

@media (max-width: 770px) {
  .horizontal-stepper-text {
    max-width: 96%;
    margin-left: 2px;
  }
  .horizontal-stepper {
    max-width: 90%;
    margin-left: 1rem;
  }
}

@media (max-width: 420px) {
  .horizontal-stepper-text {
    max-width: 100%;
    margin-left: 2px;
  }
  .horizontal-stepper {
    max-width: 85%;
    margin-left: 1rem;
  }
}
