.hero {
  padding: 30px 0;
}

.how-it-works {
  background: linear-gradient(108.1deg, #24104e 9.75%, #682fe0 91.14%);
  color: var(--color-white);
  padding: 40px 0 80px;
}

.hiw-card {
  background-color: var(--color-white);
  color: var(--color-dark-purple);
  padding: 27px;
  height: 233px;
  border-radius: 16px;
}

.hiw-count {
  font-size: 26px;
  border-radius: 50%;
  background-color: #e3cbef;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 799;
}

.hiw-step-name {
  color: var(--color-white);
  background-color: var(--color-dark-purple);
  padding: 8px 16px;
  border-radius: 8px;
}

.hiw-icon {
  font-size: 30px;
}

.experts {
  padding: 50px 0;
}

.experts .template-card {
  margin-right: 0;
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.experts .template-image {
  margin-top: auto;
  height: 180px;
  background-color: #ffe7aa;
}

.plans {
  background: linear-gradient(108.1deg, #24104e 9.75%, #682fe0 91.14%);
  padding: 50px 0 90px;
}

.plans-nav {
  margin: 0 auto;
  border: 1px solid var(--color-white);
  border-radius: 40px;
  padding: 4px;
}

.plans .nav-link {
  font-size: 16px;
  color: var(--color-white);
  border-radius: 36px;
  font-weight: 500;
}

.plans-nav .nav-link.active {
  background-color: var(--color-primary);
  color: var(--color-dark-purple);
}

.plans .plan-card {
  max-width: 350px;
}

.plans .plan-desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
li {
  color: #351771;
}

.plans .plan-features li {
  font-size: 16px;
}

.ready-start {
  padding: 50px 0;
}
