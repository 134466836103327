.footer {
  height: var(--footer-height);
  display: flex;
  align-items: center;
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-nav {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: 1023px) {
  .footer-nav {
    gap: 20px;
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .footer {
    padding: 10px;
  }

  .footer-inner {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .footer {
    border-top: 3px solid #fbe54d;
  }

  .footer-nav {
    margin-bottom: 10px !important;
    flex-wrap: wrap-reverse;
    gap: 5px 20px;
    font-size: 12px;
  }

  .footer .copyright {
    font-size: 12px;
  }
}
