@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&family=Poppins:wght@500;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
code {
  font-family: "Poppins", sans-serif;
}

.wrapper-progressBar {
  width: 100%
}



.progressBar li {
  list-style-type: none;
  float: left;
  width: 33%;
  position: relative;
  text-align: center;
}

.progressBar li:before {
  content: " ";
  line-height: 30px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: block;
  text-align: center;
  margin: 0 auto 10px;
  background-color: white
}

.progressBar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressBar li:first-child:after {
  content: none;
}

.progressBar li.active {
  color: dodgerblue;
}

.progressBar li.active:before {
  border-color: dodgerblue;
  background-color: dodgerblue;
  content:"\2713";
  color:white;
}

.progressBar li.active:after {
  background-color: dodgerblue;

}

