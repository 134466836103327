.modal-dialog {
  padding: 15px;
}
.link-modal {
  padding: 72px;
}

.modal-large {
  max-width: 1140px;
}

.modal-md {
  max-width: 640px;
}

.modal-close {
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
  transition: 0.3s all;
  border-radius: 4px;
}

.modal-close:hover {
  background-color: #f0f0f0;
}

.modal-content {
  border-radius: 16px;
  color: var(--color-dark);
}

.modal-body {
  padding: 24px 32px 32px;
}

@media (max-width: 767px) {
  .modal-body {
    padding: 24px 20px 20px;
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    padding: 0;
  }
}

@media (max-width: 414px) {
  .modal-body {
    padding: 15px;
  }
}
